<template>
  <div class="create_work">
    <van-nav-bar
      title="添加工单"
      left-text="返回"
      left-arrow
      fixed
      @click-left="onClickLeft"
    />
    <scroll class="product_scroll" :onLoadMore="onLoadMore">
      <van-form @submit="onSubmit">
        <van-field
          class="create_work_item"
          v-model="woCode"
          label="工单编号"
          name="productCode"
          input-align="center"
          left-icon=" icon icon-bitian"
          placeholder="请输入工单编号"
        />
        <van-field
          class="create_work_item"
          v-model="productList.productName"
          label="产品名称"
          left-icon=" icon icon-bitian"
          placeholder="请选择产品名称"
          input-align="center"
          @click="toAddProduct"
        />
        <van-field
          class="create_work_item"
          v-model="procedure"
          label="工艺路线"
          left-icon=" icon icon-bitian"
          placeholder="请选择工艺路线"
          input-align="center"
          @click="toCraft"
        />
        <van-field
          class="create_work_item"
          v-model="planCounts"
          type="digit"
          label="计划数"
          left-icon=" icon icon-bitian"
          placeholder="请输入计划数"
          input-align="center"
        />
        <van-field
          class="create_work_item"
          v-model="planStartTime"
          readonly
          clickable
          label="开始时间"
          left-icon=" icon icon-bitian"
          placeholder="点击选择时间"
          @click="startTimeShow = true"
        />
        <van-field
          class="create_work_item"
          v-model="planEndTime"
          readonly
          clickable
          label="结束时间"
          left-icon=" icon icon-bitian"
          placeholder="点击选择时间"
          @click="endTimeShow = true"
        />
        <customise ref="customise" :defType="defType"></customise>
        <!-- <van-field
                    v-model="remake"
                    label="备注"
                    name="remake"
                    placeholder="请输入备注"
                /> -->
        <div style="margin: 30px 16px 16px 16px" class="submit">
          <van-button round block type="primary" native-type="submit">
            创建
          </van-button>
        </div>
      </van-form>
    </scroll>
    <van-popup v-model="startTimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        @confirm="selectStartTime"
        @cancel="startTimeShow = false"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
    <van-popup v-model="endTimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        @confirm="selectEndTime"
        @cancel="endTimeShow = false"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import customise from "@/components/customise/index";
import { ChangePageTitle } from "../../common/js/upTitle";
import { mapActions, mapGetters } from "vuex";
import { addWorkOrder } from "@/api/work";
export default {
  computed: {
    ...mapGetters(["departmentCode"]),
    maxDate() {
      let curDate = new Date().getTime();
      let one = 14 * 24 * 3600 * 1000;
      let oneYear = curDate + one;
      return new Date(oneYear);
    },
  },
  watch: {
    planEndTime: {
      handler: function (val) {
        var _that = this;
        if (val) {
          if (_that.planStartTime > val) {
            _that.planEndTime = "";
            _that.$toast.fail("结束时间小于开始时间，请重新选择");
          }
        }
      },
    },
  },
  data() {
    return {
      woCode: "", //工单编号
      woState: 0, //新建工单默认状态为0
      productList: [], //产品名称集合
      procedureList: [], //工艺路线集合
      procedure: "", //工艺路线显示
      planCounts: "", //计划数
      planStartTime: "", //开始时间
      planEndTime: "", //结束时间
      minDate: new Date(),
      startTimeShow: false,
      endTimeShow: false,
      defType: 3, //工单
      show: false,
    };
  },
  created() {
    this.selectProduct();
    ChangePageTitle("添加工单");
  },
  methods: {
    onClickLeft() {
      window.localStorage.removeItem('newProcedureList')
      window.localStorage.removeItem('selectProductList')
      window.localStorage.removeItem('workList')
      this.$router.replace({ path: "/" });
    },
    selectProduct() {
      const workList =
        JSON.parse(window.localStorage.getItem("workList")) || [];
      if (workList != []) {
        this.woCode = workList.woCode;
        this.planCounts = workList.planCounts;
        this.planStartTime = workList.planStartTime;
        this.planEndTime = workList.planEndTime;
      }
      const productList =
        JSON.parse(window.localStorage.getItem("selectProductList")) || [];
      if (productList != []) {
        this.productList = productList;
      }
      const procedureAll =
        JSON.parse(window.localStorage.getItem("newProcedureList")) || [];
      if (procedureAll != []) {
        this.procedureList = procedureAll;
        var list = [];
        procedureAll.forEach((v) => {
          list.push(v.wpName);
        });
        this.procedure = list.toString();
      }
    },
    localStorageData() {
      const workList = {
        woCode: this.woCode,
        planCounts: this.planCounts,
        planStartTime: this.planStartTime,
        planEndTime: this.planEndTime,
      };
      window.localStorage.setItem("workList", JSON.stringify(workList));
    },
    selectStartTime(v) {
      this.planStartTime = this.formatter(v);
      this.startTimeShow = false;
    },
    selectEndTime(v) {
      this.planEndTime = this.formatter(v);
      this.endTimeShow = false;
    },
    formatter(val) {
      let year = val.getFullYear(); //年
      let month = val.getMonth() + 1; //月
      let day = val.getDate(); //日
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    toAddProduct() {
      this.localStorageData();
      this.$router.push("/selectProduct");
    },
    toCraft() {
      this.localStorageData();
      this.$router.push("/craft");
    },
    onSubmit() {
      if (this.woCode == ''  || this.woCode ==undefined) {
        this.$toast("请输入工单编号~");
        return
      }
      if (this.planCounts == ""  || this.planCounts ==undefined) {
        this.$toast("请输入计划数~");
        return;
      }
      if (this.planStartTime == "" || this.planStartTime ==undefined) {
        this.$toast("请选择开始时间~");
        return;
      }
      if (this.planEndTime == "" || this.planEndTime ==undefined) {
        this.$toast("请选择结束时间~");
        return;
      }
      if (this.productList == "" || this.productList ==undefined) {
        this.$toast("请选择产品名称~");
        return;
      }
      if (this.procedureList == "" || this.procedureList ==undefined) {
        this.$toast("请选择工艺路线~");
        return;
      }
      this.addWork()
    },
    async addWork(){
      try{
      const res = await this.$refs.customise.onModulSubmit();
      var wpIdList = [];
      this.procedureList.forEach((v) => {
        wpIdList.push(v.id);
      });
      var params1 = {
        woCode: this.woCode,
        woState: this.woState,
        departmentCode: this["departmentCode"],
        productCode: this.productList.productCode,
        productName: this.productList.productName,
        productUnit: this.productList.productUnit,
        productRank: this.productList.productRank,
        planCounts: this.planCounts,
        wpIdList: wpIdList,
        planStartTime: this.planStartTime,
        planEndTime: this.planEndTime,
        definitionProperties: res,
        isOrder: false
      };
      await addWorkOrder(params1);
      window.localStorage.removeItem('newProcedureList')
      window.localStorage.removeItem('selectProductList')
      window.localStorage.removeItem('workList')
      this.$router.push("/index");
      }catch(err){
        console.log(err)
      }
    }
  },
  components: {
    scroll,
    customise,
  },
};
</script>
<style lang="less" scoped>
.create_work {
  width: 100vw;
  height: 100vh;
  .product_scroll {
    padding-top: 100px;
    /deep/ .icon-bitian {
      color: red;
    }
    .create_work_item {
      width: 95%;
      margin: auto;
      margin-top: 15px;
      border-radius: 15px;
    }
    .van-cell {
      width: 95vw;
      margin: auto;
      border-radius: 5px;
      margin-top: 30px !important;
    }
    .submit {
      margin-top: 40px !important;
    }
    .model_type1 {
      border-left: 5px solid #1989fa;
    }
    .model_type2 {
      border-left: 5px solid #07c160;
    }
    .model_type3 {
      border-left: 5px solid rgb(114, 50, 221);
    }
    .model_type4 {
      border-left: 5px solid red;
      align-items: center;
      .up_img {
        width: 20vw;
        height: 20vw;
        position: relative;
        img {
          width: 100%;
        }
        .icon {
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(50%, -50%);
          color: red;
        }
      }
    }
    .model_type5 {
      border-left: 5px solid #312626;
      align-items: center;
      .van-radio--horizontal {
        margin-top: 5px;
      }
    }
    .model_type6 {
      border-left: 5px solid #4e89b3;
      align-items: center;
      .van-checkbox {
        margin-top: 5px;
      }
    }
  }
}
</style>
